import React from 'react'

import './navbar.scss'

//import lexis product icons
import ProductIcons from "../../Assets/icons/iconList"

import { DarkToggle } from "./ToogleThemeMode"

//import DownloadDocx from "./Docx"
import DownloadPdf from "./Pdf"
import { useMyContext } from "../../Context/context"

let Icono = null
const Navbar = () => {
    const { userData, productColor } = useMyContext();
    //console.log(userData)
    if (userData !== null) {
        Icono = ProductIcons[userData.SubscriptionName];
        //Icono = ProductIcons["TOTAL"];
    }



    return (
        <div className="row p-0 m-0 py-2 px-3 header-site w-100">
            <a href={window.location.origin} target="_self" rel="noopener noreferrer" className="my-auto">
                {userData !== null && <Icono className="iconDropdown" fill={productColor} ></Icono>}
            </a>
            <h4 className="my-auto pl-4 GothamRoundedBold d-none d-md-block" style={{ color: productColor }}>{userData.SubscriptionName}</h4>
            <div className="col p-0 m-0"></div>

            <div className="row p-0 m-0 my-auto p-2 navbar-right-section">

                <DarkToggle />

                <DownloadPdf />


            </div>

        </div>)
}

export default Navbar;
