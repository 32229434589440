import React from 'react'
import { FaDownload } from 'react-icons/fa';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useMyContext } from "../../Context/context"
const leftMargin=15;
const horizontalCenter=104;
export default function Pdf() {
    const { reports } = useMyContext();
    const handleDownload = () => {
        
        const doc = new jsPDF();
        // define the columns we want and their titles
        const tableColumn = ["Nombre", "Email", "Login","Consultas", "Descargas"];
        const tableColumn2 = ["Email", "Fecha","Dirección IP"];
        // define an empty array of rows
        const tableRows = [];
        const tableRows2 = [];
        
        // for each ticket pass all its data into an array
        reports.loginUsers.forEach(item => {
            const loginData = [
                item.fullName,
                item.emailAddress,
                item.numberLogin,
                item.numberQuery,
                item.numberDownload               
            ];
            // push each tickcet's info into a row
            tableRows.push(loginData);
        });

        reports.loginDetailUsers.forEach(item => {
            const loginDetailData = [               
                item.emailAddress,
                item.loginDate,
                item.ipAddress            
            ];
            // push each tickcet's info into a row
            tableRows2.push(loginDetailData);
        });


        // startY is basically margin-top
        
        const date = Date().split(" ");
        // we use a date string to generate our filename.
        const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
        // ticket title. and margin-top + margin-left
        doc.setFont("helvetica","bold")
        doc.setFontSize(14)
        doc.text("REPORTE DE USO DE "+reports.subscriptionName, horizontalCenter, 25, {align:"center"});
        doc.setFontSize(12)
        

        doc.setFont("helvetica","bold")
        doc.text("Período:", leftMargin, 35, {align:"left"});
        doc.setFont("helvetica","normal")
        doc.text("Desde " + reports.startDate + " hasta " + reports.endDate, leftMargin+25, 35, {align:"left"});
        
        doc.setFont("helvetica","bold")
        doc.text("DETALLE CONSOLIDADO DE LA CUENTA", leftMargin, 50, {align:"left"});

        doc.text("Cuenta:", leftMargin, 60, {align:"left"});
        doc.setFont("helvetica","normal")
        doc.text(reports.accountName, leftMargin+25, 60, {align:"left"});

        doc.setFont("helvetica","bold")
        doc.text("Usuarios:", leftMargin, 70, {align:"left"});
        doc.setFont("helvetica","normal")
        doc.text( reports.loginUsers.length.toString(), leftMargin+25, 70, {align:"left"});

        doc.setFont("helvetica","bold")
        doc.text("Inicios de Sesión:", leftMargin, 80, {align:"left"});
        doc.setFont("helvetica","normal")
        doc.text( reports.summarySubscription.loginSummary.toString(), leftMargin+40, 80, {align:"left"});

        doc.setFont("helvetica","bold")
        doc.text("Consultas:", leftMargin, 90, {align:"left"});
        doc.setFont("helvetica","normal")
        doc.text( reports.summarySubscription.querySummary.toString(), leftMargin+25, 90, {align:"left"});

        doc.setFont("helvetica","bold")
        doc.text("Descargas:", leftMargin, 100, {align:"left"});
        doc.setFont("helvetica","normal")
        doc.text( reports.summarySubscription.downloadSummary.toString(), leftMargin+25, 100, {align:"left"});

        doc.setFont("helvetica","bold")
        doc.text("ACCESOS PERSONALIZADOS", leftMargin, 115, {align:"left"});
        doc.autoTable(tableColumn, tableRows, { startY: 120 });

        doc.setFont("helvetica","bold")
        doc.text("DETALLE DE ACCESOS DE USUARIOS PERSONALIZADOS", leftMargin, doc.lastAutoTable.finalY+15, {align:"left"});
        doc.autoTable(tableColumn2, tableRows2, { startY: doc.lastAutoTable.finalY+20 });
        // we define the name of our PDF file.
        doc.save(`report_${dateStr}.pdf`);
    }
    return (
        <div>
            <button  disabled={!reports} onClick={() => handleDownload()} className="btn download-btn-lam-reports-rc"><FaDownload /> <span className="download-label-lam-reports-rc">Descargar</span></button>

        </div>
    )
}
