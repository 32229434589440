import React, { useState, useContext } from "react";

//jwt decode library
import jwt_decode from "jwt-decode";

//parse url parameter
const queryString = require("query-string");

// make a new context
export const MyContext = React.createContext();
export const useMyContext = () => useContext(MyContext);

// create the provider
export const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const [isDarkModeEnabled, setDarkMode] = useState(false);
  const [theme, setTheme] = useState("light");
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [reports, setReports] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [productColor, setProductColor] = useState("var(--mainTheme)");
  const [isLoading, setIsLoading] = useState(true);
  /*const [productName, setProductName] = useState("default")
  const [userId, setUserId] = useState(null)
  const [accountId, setAccountId] = useState(null)
  const [subscriptionId, setSubscriptionId] = useState(null)
  const [expirationDate, setExpirationDate] = useState(null)*/

  const apiStates = {
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
  };

  const [data, setData] = useState({
    state: apiStates.LOADING,
    error: "",
    data: [],
    enabled: true,
  });

  const setPartData = (partialData) => setData({ ...data, ...partialData });

  const updateThemeMode = (state) => {
    console.log("is dark mode enable :", state);
    setDarkMode(state);
    if (state === true) {
      setTheme("dark");
      console.log("setting to dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      console.log("setting to light");
      localStorage.setItem("theme", "light");
    }
  };

  const fetchData = async (url, bodyQuery) => {
    console.log('wwwwwwwwwwwwwwwwwwwwwwwwwww: ', url);
    var fetchedData = "";
    //console.log(apiParameters)

    setPartData({
      state: apiStates.LOADING,
    });
    //console.log("previous to fetch")
    await fetch(url, {
      method: "post",
      body: JSON.stringify(bodyQuery),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) =>
        //console.log(response)
        response.ok
          ? response.json()
          : (fetchedData = {
              state: apiStates.ERROR,
              error: response.status,
              data: null,
            })
      )
      .then((data) => {
        if (data.state !== "ERROR")
          fetchedData = {
            state: apiStates.SUCCESS,
            data,
          };

        //console.log(data)
      })
      .catch((err) => {
        //console.log(err)
        fetchedData = {
          state: apiStates.ERROR,
          error: err.status,
          data: null,
        };
      });
    //console.log(fetchedData)
    return fetchedData;
  };

  var token = "";
  React.useEffect(() => {
    var urlInfo = queryString.parseUrl(window.location.href);

    if (
      sessionStorage.getItem("token") === null ||
      sessionStorage.getItem("token") === undefined
    ) {
      token = urlInfo.query.token;
      sessionStorage.setItem("token", token);
    } else {
      token = sessionStorage.getItem("token");
    }
    console.log(token);
    if ((token !== null) & (token !== undefined) & (token !== "")) {
      try {
        var decoded = jwt_decode(token);
        console.log(decoded);
        console.log(new Date(decoded.endDate).getTime());
        console.log(new Date().getTime());

        if (new Date(decoded.endDate).getTime() > new Date().getTime()) {
          console.log("acceso autorizado");
          setUserId(decoded.userId);

          fetchData("https://lam.finder.lexis.com.ec/api/user/data", {
            userId: decoded.userId,
          }).then((data) => {
            console.log("userData: ", data.data);
            setUserData(data.data);
            let color = "var(--mainTheme";
            console.log(data.data.SubscriptionName);
            if (data.data.SubscriptionName === "Z-ONE") {
              color = "var(--mainZone)";
            } else {
              color =
                "var(--main" +
                data.data.SubscriptionName.charAt(0).toUpperCase() +
                data.data.SubscriptionName.slice(1).toLowerCase() +
                ")";
            }
            setProductColor(color);
            setIsAuthorized(true);
            setIsLoading(false);
          });
        } else {
          console.log("acceso negado else 1");
          setIsAuthorized(false);
          sessionStorage.removeItem("token");
          setIsLoading(false);
        }
      } catch {
        setIsAuthorized(false);
        console.log("acceso negado catch");
        sessionStorage.removeItem("token");
        setIsLoading(false);
      }
    } else {
      console.log("acceso negado else2");
      setIsAuthorized(false);
      sessionStorage.removeItem("token");
      setIsLoading(false);
    }
  }, []);

  const GetLexToken = async (email, servicio) => {
    const bodyQuery = {
      email,
      servicio,
    };
    const res = await fetch(
      "https://servicessecure.lexis.com.ec/api/v1/lextoken/login",
      {
        method: "post",
        body: JSON.stringify(bodyQuery),
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic c2VydmljaW9fbTI6Y2xhdmVfdGVtcG9yYWxfbGV4aXNfMjAyMQ==",
        },
      }
    );

    const data = await res.json();
    sessionStorage.setItem('lextoken', data?.access_token);
    return data;
  };

  React.useEffect(() => {
    if (userData !== null) {
      console.log("userData", userData);

      GetLexToken(userData.EmailAddress, userData.SubscriptionName).then(
        (data) => {
          var lextoken = data.access_token?.split("Bearer")[1];
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${lextoken}`);

          if (userData.AccountId !== null && userData.SubscriptionId !== null) {
            const bodyQuery = {
              accountId: userData.AccountId,
              subscriptionId: userData.SubscriptionId,
            };

            fetch(
              "https://servicessecure.lexis.com.ec/api/StatisticsService/ReportStatistics",
              {
                method: "post",
                body: JSON.stringify(bodyQuery),
                headers: myHeaders,
              }
            ).then((data) => {
              console.log("reports: ", data.data);
              setReports(data.data);
            });
          }
        }
      );
    }
  }, [userData]);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        theme,
        isDarkModeEnabled,
        reports,
        isAuthorized,
        userData,
        productColor,
        userId,
        isLoading,
        updateThemeMode,
        fetchData,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};
