import React from 'react'
import "./unauthorizedAccess.scss"
import { useMyContext } from "../../Context/context"
export default function UnauthorizedAcces() {
    const { isLoading } = useMyContext()
    if (isLoading===false) {
        window.location.href = "https://www.lexis.com.ec";
    }
    //
    return (
        <div className="unauthorized-access-lam-reports-rc">
            <p className="m-auto">Acceso Denegado</p>
        </div>
    )
}
