import React from "react";
import ReactDOM from "react-dom";
import "core-js/stable";
import "regenerator-runtime/runtime";

import App from "./App";
import { MyProvider } from "./Context/context";
import { start } from "single-spa";

import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router } from "react-router-dom"; // Import elements for routing

ReactDOM.render(
  <MyProvider>
    <Router>
      <App />
    </Router>
  </MyProvider>,
  document.getElementById("root")
);

//start();
//declare var System: any;
window.System.import("@lexis/styleguide").then(() => {
  // Activate the layout engine once the styleguide CSS is loaded
  //layoutEngine.activate();
  start();
});

//console.log("sdfsd")
/*window.System.import("@lexis/api").then((ers) => {
 console.log(ers.publicApiFunction())
   
 
})*/

/*window.System.import("@lexis/auth").then((ers) => {
  console.log(ers.login2())
    
  
 })*/
