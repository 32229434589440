import { ReactComponent as Total } from "./total.svg";
import { ReactComponent as Loyal } from "./loyal.svg";
import { ReactComponent as Legacy } from "./legacy.svg";
// import { ReactComponent as Zone } from "./zone.svg"
import { ReactComponent as Eclex } from "./eclex.svg";
import { ReactComponent as Jurlex } from "./jurlex.svg";
import { ReactComponent as Z1 } from "./Z1.svg";

let ProductIcons = {};
ProductIcons["TOTAL"] = Total;
ProductIcons["LOYAL"] = Loyal;
ProductIcons["LEGACY"] = Legacy;
ProductIcons["Z-ONE"] = Z1;
ProductIcons["ECLEX"] = Eclex;
ProductIcons["JURLEX"] = Jurlex;
ProductIcons["Z1"] = Z1;

export default ProductIcons;
