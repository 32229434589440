import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { useMyContext } from "./Context/context";

import Navbar from "./Components/Navbar/Navbar";
import UnauthorizedAccess from "./Components/UnauthorizedAccess/UnauthorizedAcces";

import { registerApplication } from "single-spa";

function App() {
  const { theme, isAuthorized, userId } = useMyContext();
  const [isAppRegistered, setIsAppRegistered] = useState(false);
  React.useEffect(() => {
    console.log(localStorage.getItem("SessionID"));
  }, []);

  React.useEffect(() => {
    if (!isAppRegistered && isAuthorized) {
      registerApplication({
        name: "lam-reports",
        app: window.System.import("@lexis/lam-reports"),
        activeWhen: "/",
        customProps: { userId: userId },
      });
      setIsAppRegistered(true);
    }
  }, [isAuthorized]);

  return (
    <div className="App w-100">
      <Helmet>
        <body
          className={
            localStorage.getItem("theme") !== null
              ? localStorage.getItem("theme")
              : theme
          }
        />
      </Helmet>

      {isAuthorized ? (
        <React.Fragment>
          <Navbar />
          <div id="single-spa-application:lam-reports"></div>
        </React.Fragment>
      ) : (
        <UnauthorizedAccess />
      )}
    </div>
  );
}

export default App;
